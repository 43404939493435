import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import MaskedInput from "react-text-mask";


import {
      Formik,
      Form,
      Field,
      ErrorMessage,
      useFormikContext
} from 'formik'



const Header = ({ data }) => {


      const showCrediton = false;
      const assets = 'assets'


      const { pathname } = useLocation();

      let isHome = pathname == "/" ? true : false;
      let isRequisitos = pathname == "/requisitos" ? true : false;
      let isMediosDePago = pathname == "/medios-de-pago" ? true : false;
      let isPromociones = pathname == "/promociones" ? true : false;
      let isPreguntas = pathname == "/preguntas-frecuentes" ? true : false;
      let isSolicitud = pathname == "/solicitar-prestamo" ? true : false;
      let isFacturacion = pathname == "/facturacion-electronica" ? true : false;
      let isTerminos = pathname == "/terminos-y-condiciones" ? true : false;


      //Form
      const campoError = 'El campo es requerido'
      var noValidate = false;
      var errors = {};

      //Setters
      const [loading, setLoading] = React.useState(false);
      const [mensaje, setMensaje] = React.useState(false);

      //Tel

      const TelMask = [
            /[0]/,
            /[9]/,
            /[0-9]/,
            " ",
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            " ",
            /[0-9]/,
            /[0-9]/,
            /[0-9]/
      ];




      return (


            <>

                  {/* HEADER */}
                  <header className={`c-header ${isPreguntas || isFacturacion || isTerminos ? 'c-header--white' : ''} `} id="c-header">
                        <div className="c-header__holder">
                              <div className="c-header__wrapper o-align-middle o-wrapper">{/*
                    */}<div className="o-align-middle__aligner">
                                          <h1 className="c-header__h1">


                                                {isHome &&

                                                      <button className="c-header__logo">
                                                            <span className="u-hidden-for-seo">{data.title}</span>
                                                      </button>

                                                }

                                                {!isHome &&

                                                      <Link to={"/"} className="c-header__logo">
                                                            <span className="u-hidden-for-seo">{data.title}</span>
                                                      </Link>

                                                }


                                          </h1>
                                          <button type="button" className="c-header__nav-btn js-show-nav" title="Ver/ocultar menú"><span className="c-header__hamburger" /></button>
                                          <div className="c-header__nav-mobile js-nav">
                                                <nav className="c-nav">
                                                      <h2 className="u-hidden-for-seo">Navegación</h2>
                                                      <ul>



                                                            {!isHome &&

                                                                  <li className={`c-nav__item`}>
                                                                        <Link to={`/`} className={`c-nav__link c-nav__link--simple ${isHome ? 'is-active' : ''}`}>Inicio</Link>
                                                                  </li>

                                                            }

                                                            {isHome &&
                                                                  <li className={`c-nav__item `}>
                                                                        <a href="#requirements" className="c-nav__link c-nav__link--simple js-nav__anchor" >Requisitos</a>
                                                                  </li>
                                                            }
                                                            {!isHome &&

                                                                  <li className={`c-nav__item`}>
                                                                        <AnchorLink to={`/#requirements`} className={`c-nav__link c-nav__link--simple ${isHome ? 'is-active' : ''}`}>Requisitos</AnchorLink>
                                                                  </li>

                                                            }

                                                            <li className={`c-nav__item`}>
                                                                  <Link to={`/medios-de-pago`} className={`c-nav__link c-nav__link--simple ${isMediosDePago ? 'is-active' : ''}`}>Medios de pago</Link>
                                                            </li>

                                                            <li className={`c-nav__item`}>
                                                                  <Link to={`/promociones`} className={`c-nav__link c-nav__link--simple ${isPromociones ? 'is-active' : ''}`}>Promociones</Link>
                                                            </li>

                                                            <li className={`c-nav__item`}>
                                                                  <Link to={`/preguntas-frecuentes`} className={`c-nav__link c-nav__link--simple ${isPreguntas ? 'is-active' : ''}`}>Preguntas</Link>
                                                            </li>

                                                            <li className={`c-nav__item`}>
                                                                  <a className="c-nav__link c-nav__link--wsp icon-wsp js-whatsapp-btn" href="#" rel="noreferrer" />
                                                            </li>

                                                            {!isSolicitud &&
                                                                  <li className={`c-nav__item c-nav__item--btn ${isSolicitud ? 'is-active' : ''}`}>
                                                                        <Link to={`/solicitar-prestamo`} className={`c-nav__link c-btn ${!showCrediton && 'c-btn--outline'}`}>Solicitar préstamo</Link>
                                                                  </li>
                                                            }

                                                      </ul>
                                                </nav>{/* .c-nav */}
                                          </div>{/* .c-header__nav-mobile */}
                                    </div>{/* .o-align-middle__aligner */}
                              </div>{/* .c-header__wrapper */}
                        </div>{/* .c-header__holder */}
                  </header>{/* .c-header */}

                  {(isSolicitud || isPreguntas || isFacturacion || isTerminos) &&
                        <div className="js-header-waypoints" id="c-header-waypoint"></div>
                  }

                  {!isSolicitud &&
                        <a className="c-wsp-btn c-btn c-btn--wsp js-whatsapp-btn" href="#" title="Escribinos por Whatsapp" rel="noreferrer"><span className="c-btn--icon icon-wsp" /><span className="c-wsp-btn__text">¡Whatsappeanos!</span></a>
                  }



                  {/* WHATSAPP POPUP */}
                  <div className="c-whatsappeanos js-popup-whatsapp o-align-middle">{/*
                        */}<div className="c-whatsappeanos__holder o-align-middle__aligner">
                              <div className="o-wrapper">
                                    <span className="c-whatsappeanos__logo js-close-whatsapp" />
                                    <span className="c-whatsappeanos__close js-close-whatsapp" />
                                    <h2 className="c-title c-title--white">Completá tus datos</h2>
                                    <span className="c-whatsappeanos__subtitle c-title--white">En instantes nos comunicaremos por WhatsApp</span>


                                    <Formik

                                          initialValues={{
                                                name: '',
                                                cellphone: '',
                                                email: '',
                                                gclid: 'xxxxxxxxxx',
                                                deluxe: showCrediton ? 'false' : 'true',
                                                promotion: 'xxx',
                                          }}
                                          onSubmit={
                                                (values, actions) => {

                                                      setLoading(true);

                                                      setMensaje('Muchas gracias, en unos segundos te estaremos contactando por WhatsApp!');


                                                      //Clean values
                                                      values.cellphone = values.cellphone.replaceAll(' ', '');
                                                      values.cellphone = values.cellphone.trim();
                                                      values.email = values.email.replaceAll(' ', '');
                                                      values.email = values.email.trim();

                                                      localStorage.getItem('gclid') != null ? values.gclid = localStorage.getItem('gclid') : values.gclid = '';
                                                      localStorage.getItem('promoid') != null ? values.promotion = localStorage.getItem('promoid') : values.promotion = '';

                                                      //Fetch to API
                                                      (async () => {

                                                            const rawResponse = await fetch(`https://becredweb.volmet.com.uy/api/v1/contact_whatsapp`, {
                                                                  method: "POST",
                                                                  headers: {
                                                                        'Accept': "application/json",
                                                                        'Content-Type': "application/json",
                                                                        'Authorization': 'Token token=fc785cfb17ba4c45b6b1'
                                                                  }, withCredentials: true,
                                                                  body: JSON.stringify({ ...values })
                                                            })

                                                            //Get API response
                                                            const response = await rawResponse.json();


                                                            if (response.status == "OK") {

                                                                  //Patch response data


                                                                  setTimeout(() => {



                                                                        //Send to whastspp

                                                                        const link = `${showCrediton ? 'https://wa.me/59891200055' : 'https://wa.me/59899270679'}`;

                                                                        const a = document.createElement("a");


                                                                        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                                                                              a.setAttribute('href', link);
                                                                        } else {
                                                                              a.setAttribute('target', '_blank');
                                                                              a.setAttribute('href', link);
                                                                        }

                                                                        a.click();

                                                                        clearTimeout(popupTimer);

                                                                        var popupTimer = null;

                                                                        popupTimer = setTimeout(function () {


                                                                              //Close popup
                                                                              var $popupWhataspp = document.getElementsByClassName('js-popup-whatsapp')[0];
                                                                              $popupWhataspp.classList.remove('is-open');


                                                                              $popupWhataspp.style.display = 'none';

                                                                        }, 1300);

                                                                        setLoading(false);
                                                                        setMensaje(null);




                                                                  }, 1200);



                                                            } else {

                                                                  setMensaje(response.message);

                                                                  setLoading(false);

                                                            }


                                                      })();

                                                }
                                          }
                                          validate={values => {

                                                noValidate = true;

                                                errors = {};

                                                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

                                                //Required
                                                Object.keys(values).
                                                      forEach((id) => {
                                                            if ((!values[id] || values[id] == "null") && id != "email") {
                                                                  errors[id] = campoError
                                                            }
                                                      })

                                                //Email      
                                                if (values.email && !emailRegex.test(values.email)) {
                                                      errors.email = "El email no es válido"
                                                }

                                                //Cellphone
                                                if (values.cellphone && (values.cellphone.length < 9 || !/^\d+$/.test(values.cellphone.replaceAll(' ', '')))) {
                                                      errors.cellphone = "El celular no es válido"
                                                }

                                                return errors;


                                          }
                                          }
                                    >
                                          {({ values }) => (


                                                <Form key="whatsapp-form" name="whatsapp-form" method="POST" className="c-form">

                                                      <div className="c-form__group">

                                                            <ErrorMessage name="name" children={() => { return (<label htmlFor="name" className="c-form__error">{errors.name}</label>) }} />

                                                            <label className="c-form__label">
                                                                  <span className="c-form__label-text">Nombre</span>
                                                                  <Field name="name" type="text" className="c-form__input" placeholder="Juan López" />
                                                            </label>

                                                      </div>
                                                      <div className="c-form__group">

                                                            <ErrorMessage name="email" children={() => { return (<label htmlFor="email" className="c-form__error">{errors.email}</label>) }} />

                                                            <label className="c-form__label">
                                                                  <span className="c-form__label-text">Email</span>
                                                                  <Field name="email" type="email" className="c-form__input" placeholder="juan.lopez@crediton.com.uy" />
                                                            </label>

                                                      </div>

                                                      <div className="c-form__group">

                                                            <ErrorMessage name="cellphone" children={() => { return (<label htmlFor="cellphone" className="c-form__error">{errors.cellphone}</label>) }} />

                                                            <label className="c-form__label">
                                                                  <span className="c-form__label-text">Celular</span>

                                                                  <Field
                                                                        name="cellphone"
                                                                        render={({ field }) => (
                                                                              <MaskedInput
                                                                                    {...field}
                                                                                    mask={TelMask}
                                                                                    placeholder="099 999 999"
                                                                                    type="tel"
                                                                                    className="c-form__input"
                                                                              />
                                                                        )}
                                                                  />

                                                            </label>

                                                      </div>



                                                      {!loading && <button type="submit" className="c-btn c-form__btn c-send__btn js-send-form"><span className={`${showCrediton ? 'c-btn__icon' : 'c-btn--icon'} icon-wsp`} />Ir a WhatsApp</button>}
                                                      {loading && <button type="button" className="c-btn c-form__btn c-send__btn js-send-form is-loading"><span className="c-loading-animation" />Cargando...</button>}

                                                      {mensaje && <div className="c-form__message c-form__message--error">
                                                            <span className="c-form__message-text c-text">{mensaje}</span>
                                                      </div>}

                                                </Form>

                                          )}

                                    </Formik>





                              </div>
                        </div>
                  </div>{/* c-whatsappeanos */}


            </>
      )
}



Header.propTypes = {
      data: PropTypes.object
}


export default Header;
