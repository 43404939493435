/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title, keywords, author, avatar, metadata }) {

  const { pathname } = useLocation()

  //La del sitio o la de la pagina
  const metaTitle = title || metadata.title
  const metaDescription = description || metadata.description
  const metaKeywords = keywords || metadata.keywords
  const metaAvatar = avatar || metadata.avatar.url
  const metaAuthor = author
  const metaUrl = metadata.url + pathname


  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={title ? `%s | ${metadata.title}` : `${metadata.title}`}
        meta={[


          //TODO: CAMBIAR por index,follow
          {
            name: `robots`,
            content: `index,follow`,
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: metaKeywords,
          },
          {
            name: `generator`,
            content: `Bloque - bloque.com.uy`,
          },
          {
            name: `author`,
            content: metaAuthor,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:site_name`,
            content: metaAuthor,
          },
          {
            property: `og:image:width`,
            content: `1200`,
          },
          {
            property: `og:image:height`,
            content: `630`,
          },
          {
            property: `og:image`,
            content: metaAvatar,
          },
          {
           property: `og:url`,
           content:metaUrl,
          },

        ].concat(meta)

        }

      >
        
          <link rel="canonical" href={metaUrl} />
          
          
        
      </Helmet>

    </>
  )
}


//CONSTRUCTOR 


SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
  title: '',
  keywords: '',
  avatar: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  avatar: PropTypes.string,
  metadata: PropTypes.object,
}


export default SEO

